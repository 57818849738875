import classNames from 'classnames';
import format from 'date-fns/format';
import { navigate } from 'gatsby-link';
import { last } from 'lodash';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DsButton } from '../../../../spedire-design-system/lib';
import { Modal } from '../../components/modal';
import { useGetTrackingQuery, usePostTicketMutation } from '../../features/ticket/ticketApi';
import { CalendarIcon, PinIcon } from '../../icons';
import { TicketPage } from '../../molecules/ticket';
import { parseDate } from '../../utils/parseDate';
import { dateFNSMappedLocales, customLanguageDetector } from '../../strings/setup';
import { Markdown } from '../../components/markdown';

const NewTicketPage = () => {
  const { t } = useTranslation();

  const queryParams = typeof document === 'object' && new URLSearchParams(document.location.search);

  const trackingCode = queryParams && queryParams?.get('trackingCode');

  const { data: response, isLoading } = useGetTrackingQuery({ trackingCode }, { skip: !trackingCode });

  const shipment = response?.data;

  const form = useForm<{ message: string }>({});

  const [postTicket, { isLoading: isLoadingPost }] = usePostTicketMutation();

  return (
    <>
      <TicketPage shipment={shipment} />
      {!isLoading && shipment && (
        <Modal open={true} onClose={() => false} size="md">
          <h1 className="pb-1 text-lg font-medium leading-6 text-gray-900">{t('ticket.new_ticket_modal.title')}</h1>
          <p className="pb-4 text-sm text-gray-600">{t('ticket.new_ticket_modal.text')}</p>
          <p className="test-gray-600 mb-1">{t('ticket.new_ticket_modal.last_update')}</p>
          <div className="rounded-md border border-gray-200 p-4 mb-4">
            <p className="text-sm font-medium leading-5 text-gray-900 mb-2 uppercase">
              {last(shipment.logs).detail || t(`tracking_page.status_info.${last(shipment.logs).code}.label_short`)}
            </p>
            <div className="flex flex-row items-center text-gray-500 mb-2">
              <CalendarIcon className="w-3 h-3 inline mr-1" />{' '}
              <p className="text-xs leading-4 font-medium ">
                {format(parseDate(last(shipment.logs).date), 'eeee dd MMMM', {
                  locale: dateFNSMappedLocales[customLanguageDetector()]
                })}
              </p>
            </div>
            <div className="flex flex-row items-center text-gray-500">
              <PinIcon className="w-3 h-3 inline mr-1" />
              <p className="text-xs leading-4 font-medium ">{last(shipment.logs).place}</p>
            </div>
          </div>

          {shipment?.managed?.message && (
            <>
              <p className="test-gray-600 mb-1">{t('ticket.new_ticket_modal.information_from_support_team')}</p>
              <div className="rounded-md border border-gray-200 p-4 mb-4">
                <p className="text-xs leading-4 font-medium ">
                  <Markdown>{shipment.managed.message}</Markdown>
                </p>
              </div>
            </>
          )}
          <form
            onSubmit={form.handleSubmit(async (data, error) => {
              const response = await postTicket({
                body: {
                  contents: data.message,
                  shipment: shipment.id
                }
              });

              if ('data' in response) {
                const id = response.data.data.id;
                navigate(`/ticket/${id}`);
              }
            })}
          >
            <div className="mb-3">
              <textarea
                {...form.register('message', { required: true })}
                placeholder={t('ticket.new_ticket_modal.message.placeholder')}
                className={classNames('rounded-md border p-4 mb-1 w-full', {
                  'border-gray-200': !form.formState.errors.message,
                  'border-red-500': form.formState.errors.message
                })}
              ></textarea>
              <p className="text-red-500 pb-3">
                {form.formState.errors.message?.type === 'required' && t('form.validations.required')}
              </p>
            </div>

            <DsButton className="w-full" type="submit" loading={isLoadingPost}>
              {t('ticket.new_ticket_modal.submit')}
            </DsButton>
          </form>
        </Modal>
      )}
    </>
  );
};

export default NewTicketPage;
